export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";

export const LOGOUT_USER = "LOGOUT_USER";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";

export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";

export const GET_SERVER_USER = "GET_SERVER_USER";
export const GET_SERVER_USER_SUCCESS = "GET_SERVER_USER_SUCCESS";

export const UPDATE_USER = "UPDATE_USER_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";

export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS";
export const UPDATE_USER_STATUS_SUCCESS = "UPDATE_USER_STATUS_SUCCESS";

export const UPDATE_PROFILE_PICTURE = "UPDATE_PROFILE_PICTURE";
export const UPDATE_PROFILE_PICTURE_SUCCESS = "UPDATE_PROFILE_PICTURE_SUCCESS";

export const SET_LOCAL_USER = "SET_LOCAL_USER";

export const API_FAILED = "AUTH_API_FAILED";