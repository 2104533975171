import { eventChannel, END } from 'redux-saga';
import { all, call, put, take, takeEvery, fork, cancel, cancelled, delay, select } from 'redux-saga/effects';

import { APIClient } from '../../helpers/apiClient';
import { enqueueSnackbar } from '../actions';

import i18n from '../../i18n'
import config from '../../config';

import {
    CREATE_CHAT,
    GET_CURRENT_CHAT,
    GET_USER_CHATS,
    SEND_MESSAGE,

    WS_SEND_MESSAGE,
    WS_SEND_MESSAGE_ACK,
    WS_DISCONNECTED,
    WS_CONNECT,
    WS_SEND_ALL_MESSAGES_ACK,
    WS_UPDATE_USER_STATUS,
    WS_CREATE_CHAT,
} from './constants';


import {
    createChatSuccess,
    getUserChatsSuccess,
    getCurrentChatSuccess,
    sendMessageSuccess,
    chatApiError,

    wsConnected,
    wsDisconnected,
    wsCreateChatSuccess,
    wsReceiveMessage,
    wsSendMessageSuccess,
    wsSendMessageAckAction,
    wsReceiveMessageAckAction,
    wsUpdateChatUser,
    wsError,
    wsConnect,

    updateUserStatusSuccess,
} from '../actions';


const create = new APIClient().create;
const get = new APIClient().get;

let socket;
let socketChannel;


//API e Local
/**
* creates new chat
*/
function* createChat({ payload: { chat } }) {
    try {
        const response = yield call(create, "/chats", chat);
        yield put(createChatSuccess(response));
    } catch (error) {
        yield put(chatApiError(error));
        yield put(enqueueSnackbar({
            message: i18n.t(error),
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }));
    }
}

/**
* Get all user chats
*/
function* getUserChats({ payload: { authId } }) {
    try {
        const response = yield call(get, "/chats/user/" + authId);
        yield put(getUserChatsSuccess(response));
    } catch (error) {
        yield put(chatApiError(error));
        yield put(enqueueSnackbar({
            message: i18n.t("There was an error making your request"),
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }));
    }
}

/**
* get all chat messages
*/
function* getCurrentChat({ payload: { chatId } }) {
    try {
        const response = yield call(get, "/chats/" + chatId);
        yield put(getCurrentChatSuccess(response));
    } catch (error) {
        yield put(chatApiError(error));
        yield put(enqueueSnackbar({
            message: i18n.t("There was an error making your request"),
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }));
    }
}

/**
* send new chat message
*/
function* sendChatMessage({ payload: { message } }) {
    try {
        const response = yield call(create, "/messages", message);
        yield put(sendMessageSuccess(response));
    } catch (error) {
        yield put(chatApiError(error));
        yield put(enqueueSnackbar({
            message: i18n.t("There was an error making your request"),
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }));
    }
}


//WebSocket
function createWebSocketConnection(userId) {
    return new Promise((resolve, reject) => {
        const socket = new WebSocket(config.WS_URL + `?userId=${userId}`);

        socket.onopen = function () {
            resolve(socket);
        };

        socket.onerror = function (evt) {
            reject(evt);
        }
    });
}

function createSocketChannel(socket) {
    return eventChannel(emit => {
        socket.onmessage = (event) => {
            emit({ type: 'MESSAGE', payload: event.data });
        };

        socket.onclose = (e) => {
            if (e.code === 1005) {
                console.log("WebSocket: closed");
                emit(END);
            } else {
                console.log('Socket is closed Unexpectedly. Reconnect will be attempted in 3 second.', e.reason);
                setTimeout(() =>  {
                    emit({ type: 'RECONNECT' });
                }, 3000);
            }
        };

        const unsubscribe = () => {
            socket.onmessage = null;
        };

        return unsubscribe;
    });
}

function* listenForSocketMessages() {
    try {
        const userId = yield select((state) => state.Auth.serverUser.id);
        socket = yield call(createWebSocketConnection, userId);
        socketChannel = yield call(createSocketChannel, socket);

        // tell the application that we have a connection
        yield put(wsConnected());

        while (true) {
            // wait for a message from the channel
            const action = yield take(socketChannel);

            const chatId = yield select((state) => state.Chat.activeChatId);
            try {
                switch (action.type) {
                    case 'MESSAGE':
                        const parsedPayload = JSON.parse(action.payload);
                        
                        //Processa mensagem recebida
                        if (parsedPayload.event === "ReceiveChatMessage") {
                            yield put(wsReceiveMessage(parsedPayload.content));
                            
                            //Envia ack de recebimento
                            if (parsedPayload.content.userId !== userId) {
                                parsedPayload.event = "MessageAck";
                                (parsedPayload.content.messageRecipients ||= []).push({
                                    chatId: parsedPayload.content.chatId,
                                    messageId: parsedPayload.content.id,
                                    userId: userId,
                                    receivedAt: new Date().toISOString(),
                                    readAt: (parsedPayload.content.chatId === chatId ? new Date().toISOString() : undefined),
                                })
                                yield put(wsSendMessageAckAction(parsedPayload));
                            }
                        } else if (parsedPayload.event === "MessageAck") { //Processa ACKs individuais recebidos
                            yield put(wsReceiveMessageAckAction(parsedPayload.content));
                        } else if (parsedPayload.event === "AllMessagesAck") { //Processa ACKs coletivos recebidos
                            yield all(parsedPayload.content.map(message => put(wsReceiveMessageAckAction(message))));
                        } else if (parsedPayload.event === "UpdateChatUser") { //Processa alteração de status dos usuários
                            yield put(wsUpdateChatUser(parsedPayload.content));;
                        } else if (parsedPayload.event === "CreateChat") { //Processa criação de nova conversa
                            yield put(wsCreateChatSuccess(parsedPayload.content));
                        }

                        break;
                    case 'RECONNECT':
                        yield put(wsConnect());
                        break;
                    default:
                        break;
                }
            } catch (e) {
                yield put(wsError(e.message));
            }
        }
    } catch (error) {
        yield put(wsError('Error while connecting to the WebSocket'));
    } finally {
        if (yield cancelled()) {
            // close the channel
            socketChannel.close();

            // close the WebSocket connection
            socket.close();
        } else {
            console.log('Socket is closed Unexpectedly. Reconnect will be attempted in 3 second.');
            yield put(wsError('WebSocket disconnected'));
            yield delay(3000);
            yield put(wsConnect());
        }
    }
}

export function* connect() {
    // starts the task in the background
    const socketTask = yield fork(listenForSocketMessages);

    // when DISCONNECT action is dispatched, we cancel the socket task
    yield take(WS_DISCONNECTED);
    yield cancel(socketTask);
    yield put(wsDisconnected());
}

/**
* creates new chat
*/
function* wsCreateChat({ payload: { chat } }) {
    try {
        const message = {
            event: "CreateChat",
            content: chat
        }
        socket.send(JSON.stringify(message));
    } catch (error) {
        yield put(chatApiError(error));
        yield put(enqueueSnackbar({
            message: i18n.t(error),
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }));
    }
}

/**
* send new chat message
*/
function* wsSendMessage({ payload: { message } }) {
    try {
        socket.send(JSON.stringify(message));
        yield put(wsSendMessageSuccess());
    } catch (error) {
        yield put(wsError(error));
        yield put (enqueueSnackbar({
            message: i18n.t("There was an error making your request"),
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }));
    }
}

/**
* send new chat message
*/
function* wsSendMessageAck({ payload: { message } }) {
    try {
        socket.send(JSON.stringify(message));
    } catch (error) {
        yield put(wsError(error));
        yield put (enqueueSnackbar({
            message: i18n.t("There was an error making your request"),
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }));
    }
}

/**
* send new chat message
* status:
*   0: received
*   1: read
*   2: received & read
*/
function* wsSendAllMessagesAck({ payload: { chatId, status } }) {
    const userId = yield select((state) => state.Auth.serverUser.id);
    try {
        const message = {
            event: "AllMessagesAck",
            content: {
                chatId: chatId,
                userId: userId,
                receivedAt: (status === 0 || status === 2 ? new Date().toISOString() : undefined),
                readAt: (status === 1 || status === 2 ? new Date().toISOString() : undefined),
            }
        }
        socket.send(JSON.stringify(message));
    } catch (error) {
        yield put(wsError(error));
        yield put (enqueueSnackbar({
            message: i18n.t("There was an error making your request"),
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }));
    }
}

/**
* send chat status
*/
function* wsUpdateUserStatus({ payload: { user, status } }) {
    try {
        const message = {
            event: "UpdateUserStatus",
            content: {
                userId: user.id,
                status,
            }
        }
        socket.send(JSON.stringify(message));
        yield put(updateUserStatusSuccess(status));
    } catch (error) {
        yield put(wsError(error));
        yield put (enqueueSnackbar({
            message: i18n.t("There was an error making your request"),
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }));
    }
}


//API e Local
export function* watchCreateChat() {
    yield takeEvery(CREATE_CHAT, createChat);
}

export function* watchGetUserChats() {
    yield takeEvery(GET_USER_CHATS, getUserChats);
}

export function* watchGetCurrentChat() {
    yield takeEvery(GET_CURRENT_CHAT, getCurrentChat);
}

export function* watchSendChatMessage() {
    yield takeEvery(SEND_MESSAGE, sendChatMessage);
}


//WebSocket
export function* watchWsConnect() {
    yield takeEvery(WS_CONNECT, connect);
}

export function* watchWsCreateChat() {
    yield takeEvery(WS_CREATE_CHAT, wsCreateChat);
}

export function* watchWsSendMessage() {
    yield takeEvery(WS_SEND_MESSAGE, wsSendMessage);
}

export function* watchWsMessageAck() {
    yield takeEvery(WS_SEND_MESSAGE_ACK, wsSendMessageAck);
}

export function* watchWsAllMessagesAck() {
    yield takeEvery(WS_SEND_ALL_MESSAGES_ACK, wsSendAllMessagesAck);
}

export function* watchWsUpdateUserStatus() {
    yield takeEvery(WS_UPDATE_USER_STATUS, wsUpdateUserStatus);
}

function* chatSaga() {
    yield all([
        fork(watchCreateChat),
        fork(watchGetUserChats),
        fork(watchGetCurrentChat),
        fork(watchSendChatMessage),
        fork(watchWsCreateChat),
        fork(watchWsMessageAck),
        fork(watchWsAllMessagesAck),
        fork(watchWsConnect),
        fork(watchWsSendMessage),
        fork(watchWsUpdateUserStatus),
    ]);
}

export default chatSaga;