import React, { 
  useContext,
  useEffect, 
  useState,
} from "react";
//import { setLocalUser } from "../../redux/actions";
//import { setAuthorization } from "../apiClient";
import app from "./config";

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {  
  const [user, setUser] = useState(null);

  useEffect(() => {
    app.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        //setLocalUser(user);
        /*user.getIdToken().then(function(idToken) {
          setAuthorization(idToken);
        });*/
      } else {
        setUser(null);
        //setLocalUser(null);
      }
    });
    app.auth().onIdTokenChanged((user) => {
      if (user) {
        setUser(user);
        //setLocalUser(user);
        /*user.getIdToken().then(function(idToken) {
          setAuthorization(idToken);
        });*/
      } else {
        setUser(null);
        //setLocalUser(null);
      }
    });
  }, []);

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(AuthContext);
};
