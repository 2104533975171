import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Chat from './chat/reducers';
import Layout from './layout/reducer';
import Notify from './snackbar/reducers';

export default combineReducers({
    Auth,
    Chat,
    Layout,
    Notify,
});