import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next  } from "react-i18next";

import translationENG from './locales/en/translation.json';
import translationPTBR from './locales/pt-BR/translation.json';

//translations
const resources = {
    en: {
        translation: translationENG
    },
    pt: {
        translation: translationPTBR
    }
};

i18n
    .use(detector)
    .use(initReactI18next ) // passes i18n down to react-i18next
    .init({
        resources,
        //load: "languageOnly",
        lng: navigator.language || navigator.userLanguage,
        fallbackLng: "pt", // use en if detected lng is not available

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
            format: (value, format, lng) => {
                var options = {};
                if (format === 'intlDate') {
                    options = {
                        year: 'numeric', month: '2-digit', day: '2-digit',
                    };
                  return new Intl.DateTimeFormat(lng, options).format(value);
                } else if (format === 'intlTime') {
                    options = {
                        hour: '2-digit', minute: '2-digit', //second: '2-digit',
                    };
                  return new Intl.DateTimeFormat(lng, options).format(value);
                } else if (format === 'intlDateTimeHideYear') {
                    options = {
                        month: '2-digit', day: '2-digit',
                        hour: '2-digit', minute: '2-digit',
                    };
                  return new Intl.DateTimeFormat(lng, options).format(value);
                } else if (format === 'intlDateTime') {
                    options = {
                        year: 'numeric', month: '2-digit', day: '2-digit',
                        hour: '2-digit', minute: '2-digit',
                    };
                  return new Intl.DateTimeFormat(lng, options).format(value);
                }
            
                return value;
            }
        }
    });

export default i18n;