import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    GET_SERVER_USER,
    GET_SERVER_USER_SUCCESS,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_STATUS,
    UPDATE_USER_STATUS_SUCCESS,
    UPDATE_PROFILE_PICTURE,
    UPDATE_PROFILE_PICTURE_SUCCESS,
    SET_LOCAL_USER,
    API_FAILED
} from './constants';

const INIT_STATE = {
    user: null,
    serverUser: null,
    loading: false
};


const Auth = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, loading: true };
        case LOGIN_USER_SUCCESS:
            return { ...state, user: action.payload, loading: false, error: null };

        case REGISTER_USER:
            return { ...state, loading: true };
        case REGISTER_USER_SUCCESS:
            return { ...state, user: action.payload, loading: false, error: null };

        case LOGOUT_USER:
            return { ...state, user: null };

        case FORGET_PASSWORD:
            return { ...state, loading: true };
        case FORGET_PASSWORD_SUCCESS:
            return { ...state, passwordResetStatus: action.payload, loading: false, error: null };

        case GET_SERVER_USER:
            return { ...state, loading: true };
        case GET_SERVER_USER_SUCCESS:
            return { ...state, serverUser: action.payload.user, loading: false, error: null };

        case UPDATE_USER:
            return { ...state, loading: true };
        case UPDATE_USER_SUCCESS:
            return { ...state, user: action.payload.user, loading: false, error: null };

        case UPDATE_USER_STATUS:
            return { ...state, loading: true };
        case UPDATE_USER_STATUS_SUCCESS:
            return {
                ...state,
                serverUser: {
                  ...state.serverUser,
                  status: action.payload,
                },
                loading: false, 
                error: null };

        case UPDATE_PROFILE_PICTURE:
            return { ...state, loading: true };
        case UPDATE_PROFILE_PICTURE_SUCCESS:
            return { ...state, user: action.payload.user, loading: false, error: null };

        case SET_LOCAL_USER:
            return { ...state, user: action.payload, loading: false, error: null };    

        case API_FAILED:
            return { ...state, loading: false, error: action.payload };

        default: return { ...state };
    }
}

export default Auth;