//1. import the dependencies
import firebase from 'firebase/compat/app';
// Add the Firebase products that you want to use
import "firebase/compat/auth";

const firebaseConfig = {
 	apiKey: process.env.REACT_APP_APIKEY,
 	authDomain: process.env.REACT_APP_AUTHDOMAIN,
 	//databaseURL: process.env.REACT_APP_DATABASEURL,
 	projectId: process.env.REACT_APP_PROJECTID,
 	storageBucket: process.env.REACT_APP_STORAGEBUCKET,
 	messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
 	appId: process.env.REACT_APP_APPID,
 	measurementId: process.env.REACT_APP_MEASUREMENTID,
 };

//2. Initialize app with the config vars
const app = firebase.initializeApp(firebaseConfig);

//3. export it for use
export default app;