import {
    CHAT_USER,
    ACTIVE_CHAT_ID,
    FULL_USER,
    ADD_LOGGED_USER,
    CREATE_GROUP,
    GET_USER_CHATS,
    GET_USER_CHATS_SUCCESS,
    GET_CURRENT_CHAT,
    GET_CURRENT_CHAT_SUCCESS,
    SEND_MESSAGE,
    SEND_MESSAGE_SUCCESS,
    API_FAILED,
    CREATE_CHAT,
    CREATE_CHAT_SUCCESS,

    WS_SEND_MESSAGE,
    WS_SEND_MESSAGE_SUCCESS,
    WS_RECEIVE_MESSAGE,
    WS_SEND_MESSAGE_ACK,
    WS_SEND_ALL_MESSAGES_ACK,
    WS_RECEIVE_MESSAGE_ACK,
    WS_UPDATE_CHAT_USER,
    WS_CONNECTED,
    WS_DISCONNECTED,
    WS_CONNECT,
    WS_FAILED,
    WS_UPDATE_USER_STATUS,
    WS_CREATE_CHAT,
    WS_CREATE_CHAT_SUCCESS,
} from './constants';


//API e Local
export const chatUser = () => ({
    type: CHAT_USER
});

export const setActiveChatId = (chatId) => ({
    type: ACTIVE_CHAT_ID,
    payload : chatId
});

export const setFullUser = (fullUser) => ({
    type: FULL_USER,
    payload : fullUser
});

export const addLoggedinUser = (userData) => ({
    type: ADD_LOGGED_USER,
    payload : userData
});

export const createGroup = (groupData) => ({
    type : CREATE_GROUP,
    payload : groupData
})

export const createChat = (chat) => ({
    type: CREATE_CHAT,
    payload: { chat }
});

export const createChatSuccess = (chat) => ({
    type: CREATE_CHAT_SUCCESS,
    payload: chat
});

export const getUserChats = (authId) => ({
    type: GET_USER_CHATS,
    payload: { authId }
});

export const getUserChatsSuccess = (chats) => ({
    type: GET_USER_CHATS_SUCCESS,
    payload: chats
});

export const getCurrentChat = (chatId) => ({
    type: GET_CURRENT_CHAT,
    payload: { chatId }
});

export const getCurrentChatSuccess = (chat) => ({
    type: GET_CURRENT_CHAT_SUCCESS,
    payload: chat
});

export const sendMessage = (message) => ({
    type: SEND_MESSAGE,
    payload: { message }
});

export const sendMessageSuccess = (message) => ({
    type: SEND_MESSAGE_SUCCESS,
    payload: message
});

export const chatApiError = (error) => ({
    type: API_FAILED,
    payload: error
});

//WebSocket
export const wsCreateChat = (chat) => ({
    type: WS_CREATE_CHAT,
    payload: { chat }
});

export const wsCreateChatSuccess = (chat) => ({
    type: WS_CREATE_CHAT_SUCCESS,
    payload: chat
});

export const wsSendMessage = (message) => ({
    type: WS_SEND_MESSAGE,
    payload: { message },
});

export const wsSendMessageSuccess = () => ({
    type: WS_SEND_MESSAGE_SUCCESS,
    payload: null,
});

export const wsReceiveMessage = (message) => ({
    type: WS_RECEIVE_MESSAGE,
    payload: message,
});

export const wsSendMessageAckAction = (message) => ({
    type: WS_SEND_MESSAGE_ACK,
    payload: { message },
});

export const wsSendAllMessagesAckAction = (chatId, status) => ({
    type: WS_SEND_ALL_MESSAGES_ACK,
    payload: { chatId, status },
});

export const wsReceiveMessageAckAction = (message) => ({
    type: WS_RECEIVE_MESSAGE_ACK,
    payload: { message },
});

export const wsUpdateChatUser = (user) => ({
    type: WS_UPDATE_CHAT_USER,
    payload: user,
});

export const wsUpdateUserStatus = (user, status) => ({
    type: WS_UPDATE_USER_STATUS,
    payload: { user, status },
});

export const wsConnected = () => ({
    type: WS_CONNECTED,
    payload: null,
});

export const wsDisconnected = () => ({
    type: WS_DISCONNECTED,
    payload: null,
});

export const wsConnect = () => ({
    type: WS_CONNECT,
    payload: null,
});

export const wsError = (error) => ({
    type: WS_FAILED,
    payload: error
});
