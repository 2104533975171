import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    GET_SERVER_USER,
    GET_SERVER_USER_SUCCESS,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_STATUS,
    UPDATE_USER_STATUS_SUCCESS,
    UPDATE_PROFILE_PICTURE,
    UPDATE_PROFILE_PICTURE_SUCCESS,
    SET_LOCAL_USER,
    API_FAILED
} from './constants';


export const loginUser = (email, password, history) => ({
    type: LOGIN_USER,
    payload: { email, password, history }
});

export const loginUserSuccess = (user) => ({
    type: LOGIN_USER_SUCCESS,
    payload: user
});

export const registerUser = (user) => ({
    type: REGISTER_USER,
    payload: { user }
});

export const registerUserSuccess = (user) => ({
    type: REGISTER_USER_SUCCESS,
    payload: user
});

export const logoutUser = (history) => ({
    type: LOGOUT_USER,
    payload: { history }
});

export const forgetPassword = (email) => ({
    type: FORGET_PASSWORD,
    payload: { email }
});

export const forgetPasswordSuccess = (passwordResetStatus) => ({
    type: FORGET_PASSWORD_SUCCESS,
    payload: passwordResetStatus
});

export const getServerUser = (authId) => ({
    type: GET_SERVER_USER,
    payload: { authId }
});

export const getServerUserSuccess = (user) => ({
    type: GET_SERVER_USER_SUCCESS,
    payload: { user }
});

export const updateUser = (user) => ({
    type: UPDATE_USER,
    payload: { user }
});

export const updateUserSuccess = (user) => ({
    type: UPDATE_USER_SUCCESS,
    payload: { user }
});

export const updateUserStatus = (user, status) => ({
    type: UPDATE_USER_STATUS,
    payload: { user, status }
});

export const updateUserStatusSuccess = (status) => ({
    type: UPDATE_USER_STATUS_SUCCESS,
    payload: status
});

export const updateProfilePicture = (file, user) => ({
    type: UPDATE_PROFILE_PICTURE,
    payload: { file, user }
});

export const updateProfilePictureSuccess = (user) => ({
    type: UPDATE_PROFILE_PICTURE_SUCCESS,
    payload: { user }
});

export const setLocalUser = (user) => ({
    type: SET_LOCAL_USER,
    payload: { user }
});

export const apiError = (error) => ({
    type: API_FAILED,
    payload: error
});