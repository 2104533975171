import {
    CHAT_USER,
    ACTIVE_CHAT_ID,
    FULL_USER,
    ADD_LOGGED_USER,
    CREATE_GROUP,
    GET_USER_CHATS,
    GET_USER_CHATS_SUCCESS,
    GET_CURRENT_CHAT,
    GET_CURRENT_CHAT_SUCCESS,
    API_FAILED,
    SEND_MESSAGE,
    SEND_MESSAGE_SUCCESS,
    CREATE_CHAT,
    CREATE_CHAT_SUCCESS,

    WS_SEND_MESSAGE,
    WS_SEND_MESSAGE_SUCCESS,
    WS_RECEIVE_MESSAGE,
    WS_RECEIVE_MESSAGE_ACK,
    WS_CONNECTED,
    WS_DISCONNECTED,
    WS_UPDATE_CHAT_USER,
    WS_CREATE_CHAT,
    WS_CREATE_CHAT_SUCCESS,
} from './constants';

const INIT_STATE = {
	activeChatId : 0,
    chats: [],
    currentChat: null,
    groups : [],
    contacts : [],
    error: null,
};

const Chat = (state = INIT_STATE, action) => {
    switch (action.type) {
        //API e Local
        case CHAT_USER:
            return { ...state };

        case ACTIVE_CHAT_ID:
            return { 
            	...state,
                activeChatId : action.payload,
                chats: state.chats.map(chat => chat.id === action.payload ?
                    { ...chat, unread: 0 } : 
                    chat
                ),
            };
                
        case FULL_USER:
            return { 
            	...state,
                chats : action.payload };

        case ADD_LOGGED_USER:
            const newUser =  action.payload
            return{
                ...state, chats : [
                    ...state.chats, newUser
                ]
            };

        case CREATE_GROUP :
            const newGroup =  action.payload
            return {
                ...state, groups : [
                    ...state.groups, newGroup
                ]
            };

        case CREATE_CHAT:
            return { ...state, loading: true };
        case CREATE_CHAT_SUCCESS:
            const newChat =  action.payload
            return {
                ...state, 
                chats : [
                    ...state.chats, newChat
                ],
                activeChatId: newChat.id,
            };
        
        case GET_USER_CHATS:
            return { ...state, loading: true };
        case GET_USER_CHATS_SUCCESS:
            return { ...state, chats: action.payload, loading: false, error: null };

        case GET_CURRENT_CHAT:
            return { ...state, currentChat: {}, loading: true };
        case GET_CURRENT_CHAT_SUCCESS:
            return { ...state, currentChat: action.payload, loading: false, error: null };
            
        case SEND_MESSAGE:
            return { ...state, loading: true };
        case SEND_MESSAGE_SUCCESS:
            return {
                ...state,
                currentChat: {
                  ...state.currentChat,
                  messages: [...state.currentChat.messages, action.payload]
                },
                chats: state.chats.map(chat => chat.id === state.activeChatId ?
                    { ...chat, lastMessage: action.payload } : 
                    chat
                ),
                loading: false, 
                error: null };

        case API_FAILED:
            return { ...state, loading: false, error: action.payload };


        
        //WebSocket
        case WS_CONNECTED:
            return { ...state, loading: true };
        case WS_DISCONNECTED:
            return { ...state, loading: true };
        case WS_CREATE_CHAT:
            return { ...state, loading: true };
        case WS_CREATE_CHAT_SUCCESS:
            const wsNewChat =  action.payload
            return {
                ...state, 
                chats : [
                    ...state.chats, wsNewChat
                ],
            };
        case WS_SEND_MESSAGE:
            return { ...state, loading: true };
        case WS_SEND_MESSAGE_SUCCESS:
            return { ...state, loading: false, error: null };
        case WS_RECEIVE_MESSAGE:
            if (state.currentChat?.id === action.payload.chatId) {
                return {
                    ...state,
                    currentChat: {
                        ...state.currentChat,
                        messages: [...state.currentChat.messages, action.payload]
                    },
                    chats: state.chats.map(chat => chat.id === action.payload.chatId ?
                        { ...chat, lastMessage: action.payload } : 
                        chat
                    ),
                    loading: false, 
                    error: null };
            } else {
                return {
                    ...state,
                    chats: state.chats.map(chat => chat.id === action.payload.chatId ?
                        { ...chat, lastMessage: action.payload, unread: chat.unread + 1 } : 
                        chat
                    ),
                    loading: false, 
                    error: null };
            }
        case WS_RECEIVE_MESSAGE_ACK:
            if (state.currentChat?.id === action.payload.message.chatId) {
                return {
                    ...state,
                    currentChat: {
                        ...state.currentChat,
                        messages: state.currentChat.messages.map(message => message.id === action.payload.message.id ?
                            { ...message, messageRecipients: action.payload.message.messageRecipients 
                            } : message
                        ),
                    },
                    loading: false, 
                    error: null };
            } else {
                return { ...state, loading: false, error: null };
            }
        case WS_UPDATE_CHAT_USER:
            return { 
                ...state, 
                chats: state.chats.map(chat => {
                    chat.users = chat.users.map(user => user.id === action.payload.id ? action.payload : user)
                    return chat;
                }),
                loading: false, 
                error: null 
            };
            
        default: return { ...state };
    }
}

export default Chat;