//API
export const CHAT_USER = "CHAT_USER";
export const ACTIVE_CHAT_ID = "ACTIVE_CHAT_ID";
export const FULL_USER = "FULL_USER";
export const ADD_LOGGED_USER = "ADD_LOGGED_USER";
export const CREATE_GROUP = "CREATE_GROUP";

export const CREATE_CHAT = "CREATE_CHAT";
export const CREATE_CHAT_SUCCESS = "CREATE_CHAT_SUCCESS";

export const GET_USER_CHATS = "GET_USER_CHATS";
export const GET_USER_CHATS_SUCCESS = "GET_USER_CHATS_SUCCESS";

export const GET_CURRENT_CHAT = "GET_CURRENT_CHAT";
export const GET_CURRENT_CHAT_SUCCESS = "GET_CURRENT_CHAT_SUCCESS";

export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";

export const API_FAILED = "CHAT_API_FAILED";


//WebSocket
export const WS_CREATE_CHAT = 'WS_CREATE_CHAT';
export const WS_CREATE_CHAT_SUCCESS = 'WS_CREATE_CHAT_SUCCESS';
export const WS_SEND_MESSAGE = 'WS_SEND_MESSAGE';
export const WS_SEND_MESSAGE_SUCCESS = 'WS_SEND_MESSAGE_SUCCESS';
export const WS_RECEIVE_MESSAGE = 'WS_RECEIVE_MESSAGE';
export const WS_SEND_MESSAGE_ACK = 'WS_SEND_MESSAGE_ACK';
export const WS_SEND_ALL_MESSAGES_ACK = 'WS_SEND_ALL_MESSAGES_ACK';
export const WS_RECEIVE_MESSAGE_ACK = 'WS_RECEIVE_MESSAGE_ACK';
export const WS_UPDATE_CHAT_USER = 'WS_UPDATE_CHAT_USER';
export const WS_UPDATE_USER_STATUS = 'WS_UPDATE_USER_STATUS';
export const WS_CONNECTED = 'WS_CONNECTED';
export const WS_DISCONNECTED = 'WS_DISCONNECTED';
export const WS_CONNECT = 'WS_CONNECT';
export const WS_FAILED = "WS_FAILED";