import firebase from 'firebase/compat/app';
// Add the Firebase products that you want to use
import "firebase/compat/auth";
import { getStorage, ref, getDownloadURL, uploadString } from "firebase/storage";

class FirebaseBackend {
    /**
     * Registers the user with given details
     */
    registerUser = (email, name, password) => {
        return new Promise((resolve, reject) => {
            if (email !== "" && password !== "" && name !== "") {
                //call the signup in function from firebase
                firebase.auth().createUserWithEmailAndPassword(email, password)
                .then(response => {
                    //once successfully signed up
                    //update the user profile with the name
                    firebase.auth().currentUser.updateProfile({displayName: name})
                    .then((response) => {
                        resolve(firebase.auth().currentUser);
                        })
                        .catch(err => {
                            //failed to update the profile
                            //move to home
                            reject(this._handleError(err));
                        });
                })
                .catch(err => {
                    //if failure for sign up, stop the spinner and show the error message
                    reject(this._handleError(err));
                });
            } else {
                reject("Preencha todos os campos");
            }
        });
    }

    /**
     * Login user with given details
     */
    loginUser = (email, password) => {
        return new Promise((resolve, reject) => {
            firebase.auth().signInWithEmailAndPassword(email, password).then((user) => {
                resolve(firebase.auth().currentUser);
            }, (error) => {
                reject(this._handleError(error));
            });
        });
    }

    /**
     * forget Password user with given details
     */
    forgetPassword = (email) => {
        return new Promise((resolve, reject) => {
            firebase.auth().sendPasswordResetEmail(email, { url: window.location.protocol + "//" + window.location.host + "/login" }).then(() => {
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            })
        });
    }

    /**
     * Logout the user
     */
    logout = () => {
        return new Promise((resolve, reject) => {
            firebase.auth().signOut().then(() => {
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            })
        });
    }

    /**
     * Updates Users data
     */
     updateUser = (name, email) => {
        return new Promise((resolve, reject) => {
            if (name !== "" && email !== "") {
                //update the user profile with the name and email
                firebase.auth().currentUser.updateProfile({displayName: name, email})
                .then((user) => {
                    resolve(firebase.auth().currentUser);
                })
                .catch(err => {
                    //failed to update the profile
                    //move to home
                    reject(this._handleError(err));
                });
            } else {
                reject("Preencha todos os campos");
            }
        });
    }

    /**
     * Updates Users Profile Picture
     */
     uploadProfilePicture = (fileURL) => {
        return new Promise((resolve, reject) => {
            const storage = getStorage();
            const storageRef = ref(storage, `profile_pictures/${firebase.auth().currentUser.uid}.png`);
            uploadString(storageRef, fileURL, 'data_url')
                .then((snapshot) => {
                    getDownloadURL(snapshot.ref).then((downloadURL) => {
                        firebase.auth().currentUser.updateProfile({photoURL: downloadURL}).then((user) => {
                            resolve(firebase.auth().currentUser);
                        })
                        .catch(err => {
                            reject(this._handleError(err));
                        });
                    });
                }).catch((error) => {
                    reject(error);
                });
        });
    }

    /**
     * Handle the error
     * @param {*} error 
     */
    _handleError(error) {
        // var errorCode = error.code;
        var errorMessage = error.message;
        return errorMessage;
    }
}


let _fireBaseBackend = null;

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
    if (!_fireBaseBackend) {
        _fireBaseBackend = new FirebaseBackend();
    }
    return _fireBaseBackend;
}

export { getFirebaseBackend };