import React from 'react';
import Routes from './routes/';
import { AuthProvider } from "./helpers/firebase/authContext";

//Import Scss
import "./assets/scss/themes.scss";

const App = () => {
  return <AuthProvider><Routes /></AuthProvider>;
};

export default App;
