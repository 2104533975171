import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import { SnackbarProvider } from 'notistack';
import Notifier from "./components/Snackbar/Notifier"

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={configureStore()}>
      <SnackbarProvider maxSnack={3}>
        <Notifier />
        <App />
      </SnackbarProvider>
    </Provider>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
