import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { APIClient } from '../../helpers/apiClient';
import { getFirebaseBackend } from "../../helpers/firebase/firebase";

import i18n from '../../i18n'

import {
    LOGIN_USER,
    LOGOUT_USER,
    REGISTER_USER,
    FORGET_PASSWORD,
    GET_SERVER_USER,
    UPDATE_USER,
    UPDATE_PROFILE_PICTURE,
    UPDATE_USER_STATUS,
} from './constants';


import {
    loginUserSuccess,
    registerUserSuccess,
    forgetPasswordSuccess,
    getServerUserSuccess,
    updateUserSuccess,
    updateUserStatusSuccess,
    updateProfilePictureSuccess,
    enqueueSnackbar,
    apiError
} from '../actions';

//Initilize firebase
const fireBaseBackend = getFirebaseBackend();

/**
 * Sets the session
 * @param {*} user 
 */

const create = new APIClient().create;
const update = new APIClient().update;
const get = new APIClient().get;

/**
 * Login the user
 * @param {*} payload - email and password 
 */
function* login({ payload: { email, password, history } }) {
    try {
        if(process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            const response = yield call(fireBaseBackend.loginUser, email, password);
            yield put(loginUserSuccess(response));
        } else {
            const response = yield call(create, '/login', { email, password });
            yield put(loginUserSuccess(response));
        }
        history.push('/dashboard');
    } catch (error) {
        yield put(apiError(error));
        yield put (enqueueSnackbar({
            message: i18n.t("There was an error making your request"),
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }));
    }
}


/**
 * Logout the user
 * @param {*} param0 
 */
function* logout({ payload: { history } }) {
    try {
        if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
            yield call(fireBaseBackend.logout);
        }
        yield call(() => {
            history.push("/login");
        });
    } catch (error) { }
}

/**
 * Register the user
 */
function* register({ payload: { user } }) {
    try {
        const email = user.email;
        const name = user.name;
        const password = user.password;
        if(process.env.REACT_APP_DEFAULTAUTH === "firebase"){
            const response = yield call(fireBaseBackend.registerUser, email, name, password);
            yield put(registerUserSuccess(response));
            yield call(create, "/users", {
                authId: response.uid,
                displayName: name,
                email,
                photoURL: "",
                tenentId: 0,
            });
        } else {
            const response = yield call(create, '/register', user);
            yield put(registerUserSuccess(response));
        }
        
    } catch (error) {
        yield put(apiError(error));
        yield put (enqueueSnackbar({
            message: i18n.t("There was an error making your request"),
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }));
    }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { email } }) {
    try {
        if(process.env.REACT_APP_DEFAULTAUTH === "firebase"){
            const response = yield call(fireBaseBackend.forgetPassword, email);
            if (response) {
              yield put(
                forgetPasswordSuccess(
                    i18n.t("Reset link was sent to your mailbox, check there first")
                )
              );
            }
        } else {
            const response = yield call(create, '/forget-pwd', { email });
            yield put(forgetPasswordSuccess(response));
        }
    } catch (error) {
        yield put(apiError(error));
        yield put (enqueueSnackbar({
            message: i18n.t("There was an error making your request"),
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }));
    }
}

/**
 * Register the user
 */
 function* updateUser({ payload: { user } }) {
    try {
        const authId = user.authId;
        const displayName = user.name;
        const email = user.email;
        if(process.env.REACT_APP_DEFAULTAUTH === "firebase"){
            const response = yield call(fireBaseBackend.updateUser, displayName, email);
            yield put(updateUserSuccess(response));
            yield call(update, "/users/" + authId, {
                displayName,
                email,
            });
            yield put (enqueueSnackbar({
                message: i18n.t("Your data has been successfully updated"),
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                },
            }));
        }         
    } catch (error) {
        yield put(apiError(error));
        yield put (enqueueSnackbar({
            message: i18n.t("There was an error making your request"),
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }));
    }
}

/**
 * Updates the user status
 */
 function* updateUserStatus({ payload: { user, status } }) {
    try {
        const authId = user.uid;
        if(process.env.REACT_APP_DEFAULTAUTH === "firebase"){
            yield call(update, "/users/" + authId, {
                status,
            });
            yield put(updateUserStatusSuccess(status));
            yield put (enqueueSnackbar({
                message: i18n.t("Your data has been successfully updated"),
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                },
            }));
        }         
    } catch (error) {
        yield put(apiError(error));
        yield put (enqueueSnackbar({
            message: i18n.t("There was an error making your request"),
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }));
    }
}

/**
 * Updates users profile picture
 */
 function* uploadProfilePicture({ payload: { file, user } }) {
    try {
        const authId = user.uid;

        if(process.env.REACT_APP_DEFAULTAUTH === "firebase"){
            const response = yield call(fireBaseBackend.uploadProfilePicture, file);
            const photoUrl = response.photoURL;
            
            yield put(updateProfilePictureSuccess(response));
            yield call(update, "/users/" + authId, {
                photoUrl,
            });
            yield put(enqueueSnackbar({
                message: i18n.t("Your data has been successfully updated"),
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                },
            }));
        }         
    } catch (error) {
        yield put(apiError(error));
        yield put(enqueueSnackbar({
            message: i18n.t("There was an error making your request"),
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }));
    }
}

/**
 * Updates users profile picture
 */
 function* getServerUser({ payload: { authId } }) {
    try {
        const response = yield call(get, "/users/" + authId);
        yield put(getServerUserSuccess(response));
    } catch (error) {
        yield put(apiError(error));
        yield put(enqueueSnackbar({
            message: i18n.t("There was an error making your request"),
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }));
    }
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword() {
    yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

export function* watchGetServerUser() {
    yield takeEvery(GET_SERVER_USER, getServerUser);
}

export function* watchUpdateUser() {
    yield takeEvery(UPDATE_USER, updateUser);
}

export function* watchUpdateUserStatus() {
    yield takeEvery(UPDATE_USER_STATUS, updateUserStatus);
}

export function* watchUpdateProfilePicture() {
    yield takeEvery(UPDATE_PROFILE_PICTURE, uploadProfilePicture);
}

function* authSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchForgetPassword),
        fork(watchGetServerUser),
        fork(watchUpdateUser),
        fork(watchUpdateUserStatus),
        fork(watchUpdateProfilePicture),
    ]);
}

export default authSaga;